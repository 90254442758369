@import "../../../../mac_base/scss/theme-bootstrap";

.header-search__form.tl-trends-v1__form {
  .header-search__form--content.responsive-container {
    .header-search__typeahead-wrapper.text-empty {
      display: none;
    }
  }
}

.tl-trends-v1 {
  position: relative;
}

.trendalytics-search__container {
  position: relative;
  background: $white;
  color: $black;
  display: block;
  .trendalytics-app-wrapper {
    margin-top: 1px;
    &.tl-full-screen {
      margin-top: 0px;
      position: fixed;
      top: 0;
      bottom: 0;
      height: 100vh;
      width: 100vw;
      background: $white;
      overflow-y: scroll;
      overflow-x: hidden;
      z-index: 2;
    }
    .search-results-view {
      text-align: center;
      padding-bottom: 60px;
    }
  }
  .trendspotting {
    margin: 15px 0;
    padding: 0 15px;
    &__title {
      text-align: center;
      font-family: $ano-bold_regular-font;
      margin: 0 0 10px;
      letter-spacing: 0;
    }
    &__items {
      display: block;
      flex-wrap: nowrap;
      overflow-x: hidden;
      margin-bottom: 15px;
    }
    &-item {
      flex: 0 0 auto;
      @media screen and (max-width: $width-xxsmall) {
        width: 134px !important; // override slick-carousel injecting style attributes
        height: 134px !important; // override slick-carousel injecting style attributes
      }
      @media screen and (min-width: $width-xxsmall) {
        width: 110px !important; // override slick-carousel injecting style attributes
        height: 110px !important; // override slick-carousel injecting style attributes
      }
      border-bottom: 0px;
      img {
        min-width: 100%;
        min-height: 100%;
        max-height: 100%;
        object-fit: cover;
      }
      &__only-text {
        text-align: center;
        background: $black;
        color: $white;
        font-family: $ano-bold_regular-font;
        font-size: 18px;
        padding-top: 25px;
      }
    }

    // override existing slick-dots scss
    .slick-dots {
      bottom: -30px;
      & > li {
        display: inline-block;
      }
    }
  }
  .trendspotting-page {
    text-align: center;
    .search-results__title,
    .search-results__summary {
      padding: 0 15px;
    }
    .search-results-view__back {
      top: 1px;
    }
    .btn {
      margin: 20px auto;
    }
    .trendspotting-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 128px;
      &__item {
        width: 49.6vw;
        margin-bottom: 5px;
        &__img {
          height: 49vw;
          width: 49vw;
          object-fit: cover;
        }
        &--large {
          width: 100%;
        }
      }
    }
  }
  .product-page {
    margin: 90px auto 50px;
    text-align: center;
  }
  .product-detail {
    text-align: center;
  }
  .product__image-medium-link {
    display: block;
  }
  .product-result-section {
    padding: 0 15px 75px;
  }
  .product--teaser .product__detail .product__rating-stars .product__rating-percentage {
    #{$ldirection}: 0;
  }
  .overlay-gray-background {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    bottom: 0;
    background: $black;
    opacity: .95;
  }
  .overlay-wrapper {
    height: 100vh;
    .icon.icon--remove.icon--blackbg {
      position: absolute;
      #{$rdirection}: 10px;
      top: 10px;
      z-index: 1500;
      color: $white;
      text-shadow: 0 2px $color--black--opacity;
      font-weight: 900;
      font-size: $typesize--13px;
      padding: 12px;
      background: $black;
      border-radius: 100%;
      opacity: .6;
    }
  }
  .trendspotting-item-overlay {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    background: $white;
    z-index: 1000;
    text-align: center;
    padding-bottom: 90px;
    &__caption-container {
      padding: 20px;
      text-align: #{$ldirection};
      word-break: break-word;
    }
    &__image-container {
      position: relative;
    }
    &__image {
      width: 100%;
    }
    &__previous,
    &__next {
      position: absolute;
      z-index: 1500;
      font-size: $typesize--26px;
      color: $white;
      text-shadow: 0px 3px $color--black--opacity;
      top: 160px;
    }
    &__previous {
      #{$ldirection}: 10px;
      @include swap_direction(padding, 10px 15px 10px 2px);
    }
    &__next {
      #{$rdirection}: 10px;
      @include swap_direction(padding, 10px 2px 10px 15px);
    }
    &__caption {
      font-family: $roboto-mono_regular-font;
      font-size: $typesize--12px;
    }
    &__author {
      display: flex;
      margin-bottom: 15px;
      &__avatar {
        width: 60px;
        height: 60px;
        margin-#{$rdirection}: 10px;
      }
      &__names {
        text-transform: uppercase;
        &__handle-name,
        &__real-name {
          margin: 0;
        }
        &__handle-name {
          font-family: $ano-bold_regular-font;
          font-size: $typesize--22px;
        }
        &__handle-link {
          border-bottom: none;
          color: inherit;
        }
        &__real-name {
          font-family: $roboto-mono_regular-font;
          font-size: $typesize--12px;
        }
      }
    }
    &__products {
      border-top: $border;
      padding: 20px;
      margin: 15px;
      &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      @media screen and (max-width: $width-xsmall) {
        .btn {
          font-size: $typesize--11px;
        }
      }
      @include breakpoint($width-xsmall) {
        .btn {
          font-size: $typesize--16px;
        }
      }
    }
    &__product {
      text-align: #{$ldirection};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-bottom: 0;
      width: 45%;
      margin-bottom: 30px;
      &__title {
        font-family: $ano-bold_regular-font;
        font-size: $typesize--16px;
        text-transform: uppercase;
      }
      &__link {
        border-bottom: 0;
      }
      .btn {
        display: block;
        margin: 0 auto;
      }
    }
    &__add-look {
      &__title {
        font-size: $typesize--24px;
        margin-bottom: 10px;
      }
      .btn {
        margin-bottom: 0;
      }
    }
  }
  .product__detail {
    .product__header {
      .product__price {
        &--with-discount {
          max-width: 170px;
          .product__price--discounted {
            text-decoration: line-through;
          }
          .product__price--standard:not(.product__price--discounted) {
            color: $color--red;
            margin-#{$ldirection}: 10px;
          }
        }
      }
    }
  }
  .search-results {
    display: block;
    text-align: #{$ldirection};
    text-decoration: underline;
    &__results {
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
    }
    &-view {
      #product-detail-attach > header > div {
        text-align: #{$ldirection};
      }
      .search-results__main-title {
        font-size: $typesize--14px;
        font-family: $roboto-mono_regular-font;
        text-align: center;
        text-transform: uppercase;
        margin: 20px 0 0;
      }
      &__back {
        position: absolute;
        font-size: $typesize--22px;
        #{$ldirection}: 15px;
        top: 76px;
      }
      .results-notification {
        position: fixed;
        bottom: 0;
        color: $white;
        text-align: center;
        font-family: $roboto-mono_regular-font;
        font-size: $typesize--14px;
        background: $color--black--opacity;
        padding: 20px 0;
        width: 100%;
        max-width: 100vw;
        z-index: 1000;
        &__close {
          @include thick-close();
        }
        .icon {
          display: block;
          font-size: $typesize--24px;
          margin: 5px auto 0;
        }
      }
    }
    &__title {
      font-size: $typesize--30px;
      margin-bottom: 5px;
      position: relative;
      margin-top: 12px;
    }
    &__summary {
      text-align: center;
      text-transform: uppercase;
      font-size: $typesize--12px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    &__products-wrapper {
      padding: 0 15px 75px;
    }
    &__products-container {
      text-align: center;
      .product__header {
        text-align: #{$ldirection};
      }
      @media screen and (max-width: $width-xsmall) {
        .product__view-all {
          font-size: $typesize--15px;
          padding: 0;
        }
      }
      @include breakpoint($width-xsmall) {
        .product__view-all {
          font-size: $typesize--15px;
        }
      }
      .product__name {
        letter-spacing: 0;
      }
    }
  }
  .btn--light {
    color: $black;
  }
  .carousel {
    margin-bottom: 40px;
    .product--teaser {
      border: none;
      outline: none;
    }
    .slick-dots {
      bottom: -20px;
    }
  }
  .grid--mpp__item:first-child .product--teaser {
    border: none;
    outline: none;
  }
  .product-group__title {
    text-align: #{$ldirection};
  }
  .product-group__title + .grid--mpp__item {
    .product--teaser {
      border: 0;
    }
  }
  .product-teaser {
    .product__sku-details {
      .product_header_details {
        text-align: #{$ldirection};
        .product__name-link {
          @include unset-reverse-text;
        }
        .product__mini-detail,
        .product__description-short {
          font-family: $roboto-mono_regular-font;
        }
      }
    }
  }
  .result-sort {
    margin: 10px 0 30px;
  }
  .autocomplete__loading {
    position: relative;
    top: 70px;
    background: $white;
    height: 100vh;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    text-align: center;
    padding-top: 30px;
    &__icon {
      @include swap_direction(transform, translateX(-50%));
      position: absolute;
      top: 10%;
      #{$ldirection}: 50%;
    }
  }
  .header-search__form {
    position: relative;
    border-bottom: 0;
    height: auto;
    .header-search__close {
      position: absolute;
      top: 16px;
      #{$rdirection}: 25px;
    }
    &--content {
      padding: 14px 22px;
    }
  }
  .header-search__result {
    letter-spacing: normal;
    &__title {
      margin-bottom: 10px;
      font-size: $typesize--18px;
      color: $color--title-red;
      letter-spacing: 2px;
    }
    &__body {
      margin-bottom: 10px;
    }
    &__link {
      border-bottom: 0;
      text-transform: uppercase;
      color: $black;
    }
    &__type {
      text-transform: uppercase;
      color: $color--red;
      font-family: $roboto-mono_regular-font;
      &--buzzwords {
        color: $black;
      }
    }
  }
  .header-search__field {
    border: 0;
    border-bottom: 1px solid $color--gray--lighter;
    font-family: $ano-bold_regular-font;
    font-size: 2rem;
    text-transform: uppercase;
    -webkit-appearance: none;

    // This is needed to override the global style
    &:focus {
      border: 0;
      border-bottom: 1px solid $color--gray--lighter;
      -webkit-appearance: none;
    }
    &::placeholder {
      font-size: 1em;
    }
  }
  .header-search__results {
    text-align: #{$ldirection};
    border: 0;
  }
  .header-search__term-result-metadata {
    padding: $typesize--14px 0;
    font-family: $roboto-mono_regular-font;
    border-bottom: 1px solid $color--gray--lighter;
    margin-bottom: 10px;
    &--initial {
      border-bottom: 0;
      padding-bottom: 0;
      font-family: $ano-bold_regular-font;
      font-size: $typesize--16px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  .header-search__term-result {
    color: $black;
  }
  .search-count {
    display: block;
    text-align: #{$ldirection};
  }
  .result-count-message {
    text-align: center;
    text-transform: uppercase;
    font-family: $roboto-mono_regular-font;
    margin: 20px 0 15px;
    padding: 0 15px 20px;
    font-size: $typesize--14px;
    border-bottom: $border;
    &__showing-for {
      font-family: $ano-bold_regular-font;
      font-size: $typesize--24px;
      margin-bottom: 20px;
    }
    &__suggestion__content {
      font-family: $roboto-mono_bold-font;
      text-decoration: underline;
      strong {
        font-size: $typesize--14px;
      }
    }
    &--with-results {
      border-bottom: 0;
      .result-count-message__showing-for,
      .result-count-message__suggestion {
        font-family: $roboto-mono_regular-font;
        font-size: $typesize--14px;
        text-decoration: none;
        strong {
          font-size: $typesize--14px;
          font-weight: normal;
        }
      }
      .result-count-message__showing-for {
        margin-bottom: 0;
      }
      .result-count-message__suggestion__content {
        text-decoration: none;
        strong {
          text-decoration: underline;
          font-weight: bold;
        }
      }
    }
  }
  .add-to-cart-overlay {
    position: fixed;
    top: 0;
    #{$rdirection}: 0;
    background: $white;
    width: 90%;
    z-index: 9999;
    font-family: $roboto-mono_regular-font;
    border: $border;
    text-align: center;
    &__title {
      font-size: $typesize--24px;
      padding: 15px;
      text-align: #{$ldirection};
    }
    &__edit-btn {
      position: absolute;
      #{$rdirection}: 65px;
      top: 15px;
    }
    &__icon-wrapper {
      position: absolute;
      #{$rdirection}: 20px;
      top: 12px;
    }
    &__product {
      display: flex;
      text-align: #{$ldirection};
      margin: 25px 0;
      padding: 0 15px;
      border-bottom: $border;
      &-data {
        font-family: $roboto-mono_regular-font;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-transform: uppercase;
        &__qty {
          margin: 0;
        }
        &__price {
          font-family: $ano-bold_regular-font;
          font-size: $typesize--16px;
        }
      }
      &__image {
        max-height: 150px;
        margin-bottom: 10px;
      }
    }
    &__bottom-container {
      padding: 0 15px 15px;
    }
    &__bottom-copy {
      text-align: center;
      text-transform: uppercase;
      margin: 0;
    }
    .btn {
      margin-bottom: 10px;
    }
    .icon.icon--remove {
      &:before {
        font-size: $typesize--27px;
      }
    }
  }
  .overlay-wrapper .add-to-cart-overlay .icon.icon--remove {
    text-shadow: none;
    font-weight: normal;
    color: $black;
  }
  .look-bundles {
    padding: 0 15px;
    margin-bottom: 20px;
    min-height: 360px;
    &__title {
      text-align: center;
      font-family: $ano-bold_regular-font;
    }
    &__index {
      position: absolute;
      #{$ldirection}: 50%;
      transform: translateX(-50%);
    }
    .carousel {
      margin-bottom: 0;
      position: relative;
      .slick-slide {
        &:after {
          width: 100%;
          height: 1px;
          background: $color--red;
          content: '';
        }
      }
      .slick-prev,
      .slick-next {
        bottom: -30px;
        height: 50px;
        z-index: 100;
      }
    }
  }
  .search-filter {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    bottom: 0;
    z-index: 500;
    background: $white;
    text-align: #{$ldirection};
    padding: 15px;
    .search-filter__button-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
    &__label {
      font-family: $ano-bold_regular-font;
      font-size: $typesize--14px;
      text-transform: uppercase;
    }
    .btn--clear-bg {
      font-family: $roboto-mono_regular-font;
      font-size: $typesize--12px;
      color: $black;
      text-decoration: underline;
      margin-#{$ldirection}: 100px;
    }
    .search-filter__filter {
      margin-bottom: 1px;
      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        .search-filter__trigger_icon--right {
          transform: rotate(90deg);
          position: initial;
          &:before {
            color: $white;
          }
        }
      }
      &--open {
        .search-filter__filter__title {
          .search-filter__trigger_icon--right {
            transform: rotate(-90deg);
          }
        }
        .search-filter__filter__values-container {
          display: block;
        }
      }
      &__values-container {
        display: none;
      }
      &__constraint {
        border-top: $border;
        position: relative;
        &:before {
          position: absolute;
          content: '';
          top: 16px;
          #{$ldirection}: $typesize--12px;
          border: $border;
          border-radius: 4px;
          width: 16px;
          height: 16px;
          line-height: 18px;
          text-align: center;
          transition-property: all;
          transition-duration: 150ms;
          transition-timing-function: ease-out;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 20px;
          #{$ldirection}: 18px;
          opacity: 0;
          visibility: hidden;
          transition-property: all;
          transition-duration: 150ms;
          transition-timing-function: ease-out;
          width: 3px;
          height: 6px;
          border: solid $black;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
        &__title {
          font-family: $roboto-mono_regular-font;
          font-size: $typesize--12px;
          text-transform: uppercase;
          color: $color--gray;
          padding: 5px 10px 5px 38px;
          display: block;
          line-height: 42px;
        }
        &--checked {
          &:before {
            border-color: $black;
          }
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
  .search-filter__applied-constraints {
    font-family: $roboto-mono_regular-font;
    font-size: $typesize--12px;
    text-transform: uppercase;
    text-align: #{$ldirection};
    margin-top: 10px;
    &__container {
      display: flex;
      flex-wrap: wrap;
    }
    &__title {
      font-family: $roboto-mono_bold-font;
    }
    &__btn {
      margin-#{$ldirection}: 0;
      padding-#{$ldirection}: 0;
    }
    .search-filter__applied-constraint {
      margin-#{$rdirection}: 5px;
      &:last-child {
        .search-filter__applied-filter__separator {
          display: none;
        }
      }
    }
    .search-filter__applied-filter {
      margin-#{$rdirection}: 15px;
    }
    .search-filter__applied-filters__btn {
      font-family: $roboto-mono_regular-font;
      font-size: $typesize--12px;
      color: $black;
      padding: 0;
      text-decoration: underline;
    }
  }
}
